import React from 'react';
import './list.scss';
import Sidebar from "../../comonents/sidebar/Sidebar";
import Navbar from "../../comonents/navbar/Navbar";
import {Datatable} from "../../comonents/datatable/Datatable";

const List = () => {
    return (
        <div className='list'>
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <Datatable />
            </div>
        </div>
    )
};

export default List;