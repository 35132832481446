import React, {useState} from 'react';
import './datatable.scss';
import {DataGrid} from '@mui/x-data-grid';
import {userColumns, userRows} from "../../datatablesource";
import {Link} from "react-router-dom";

export const Datatable = () => {
    const [data, setData] = useState(userRows);
    const handleDelete = (id) => {
        setData(data.filter(item => item.id !== id));
    };

    const actionColumn = [{
        field: 'action',
        headerName: 'Action',
        width: 200,
        renderCell: (params) => (
            <div className='cellAction'>
                <Link to='/users/test' style={{textDecoration: "none"}}>
                    <div className='viewButton'>View</div>
                </Link>
                <div
                    className='deleteButton'
                    onClick={() => handleDelete(params.row.id)}
                >Delete</div>
            </div>
        )
    }]
    return (
        <div className='datatable'>
            <div className="datatableTitle">
                Dodaj nowego użytkownika
                <Link to='/users/new' className='link'>
                    Dodaj
                </Link>
            </div>
            <DataGrid
                className='datagrid'
                rows={data}
                columns={userColumns.concat(actionColumn)}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />
        </div>
    )
};
