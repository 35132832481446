import React from 'react';
import './home.scss';
import Sidebar from "../../comonents/sidebar/Sidebar";
import Navbar from "../../comonents/navbar/Navbar";
import Widget from "../../comonents/widget/Widget";
import Featured from "../../comonents/featured/Featured";
import Chart from "../../comonents/chart/Chart";
import {List} from "../../comonents/table/Table";

const Home = () => {
    return (
        <div className='home'>
            <Sidebar />
            <div className="homeContainer">
                <Navbar />
                <div className="widgets">
                    <Widget type="user"/>
                    <Widget type="order"/>
                    <Widget type="earning"/>
                    <Widget type="balance"/>
                </div>
                <div className="charts">
                    <Featured />
                    <Chart aspect={2/1} title={'Ostatnie 6 miesięcy'}/>
                </div>
                <div className="listContainer">
                    <div className="listTitle">Ostatnie transakcje</div>
                    <List />
                </div>
            </div>
        </div>
    )
};

export default Home;