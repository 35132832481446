import React from 'react';
import './single.scss';
import Sidebar from "../../comonents/sidebar/Sidebar";
import Navbar from "../../comonents/navbar/Navbar";
import Chart from "../../comonents/chart/Chart";
import {List} from "../../comonents/table/Table";

const Single = () => {
    return (
        <div className='single'>
            <Sidebar />
            <div className="singleContainer">
                <Navbar />
                <div className="top">
                    <div className="left">
                        <div className="editButton">Edytuj</div>
                        <h1 className='title'>Informacje</h1>
                        <div className="item">
                            <img
                                src="https://media.istockphoto.com/id/1200677760/pl/zdj%C4%99cie/portret-przystojnego-u%C5%9Bmiechni%C4%99tego-m%C5%82odego-m%C4%99%C5%BCczyzny-ze-skrzy%C5%BCowanymi-ramionami.jpg?s=612x612&w=is&k=20&c=SMwF92lSVCI81n_t63uH4wGaGkT3ou530i_wZ3s09Gs="
                                alt="image"
                                className="itemImg"/>
                            <div className="details">
                                <h1 className='itemTitle'>Rafał Kowalski</h1>
                                <div className="detailItem">
                                    <span className="itemKey">Email:</span>
                                    <span className="itemValue">r.kowalski@gba-polska.pl</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Telefon:</span>
                                    <span className="itemValue">+48 123 321 123</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Adres:</span>
                                    <span className="itemValue">Kobiałka, ul. Mochtyńska 65</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Kraj:</span>
                                    <span className="itemValue">Polska</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right"></div>
                    <Chart aspect={3/1} title={'Wydatki (ostatnie 6 mieszięcy)'}/>
                </div>
                <div className="bottom">
                    <h1 className='title'>Ostatnie transakcje</h1>
                    <List />
                </div>
            </div>
        </div>
    )
};
export default Single;