import React from 'react';
import './table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const rows = [
    {
        id: 123123123,
        product: 'Acer Nitro 5',
        img: 'https://image.ceneostatic.pl/data/products/128477500/i-acer-nitro-5-15-6-i5-16gb-512gb-noos-nh-qesep-00c.jpg',
        customer: 'Rafał Kuchta',
        date: '1 March',
        amount: 758,
        method: 'Cash on Delivery',
        status: 'Approved',
    },
    {
        id: 342141234,
        product: 'Playstation 5',
        img: 'https://f01.esfr.pl/foto/6/114649221465/12799c0da1fb7e525acae59fb7100a4d/sony-playstation-5-ps5-god-of-war-ragnarok-uncharted-kolekcja-dziedzictwo-zlodziei-spider-man-miles-morales,114649221465_3.jpg',
        customer: 'Rafał Kowalski',
        date: '1 March',
        amount: 900,
        method: 'Online Payment',
        status: 'Pending',
    },
    {
        id: 4535245324,
        product: 'Dell',
        img: 'https://f00.esfr.pl/foto/6/105860395017/557f15026877c304012cac6bd7ed1b6/dell-laptop-5510-i5-16gb-512ssd-w11,105860395017_8.jpg',
        customer: 'Krzysztof Nowak',
        date: '10 March',
        amount: 1500,
        method: 'Cash on Delivery',
        status: 'Approved',
    },
    {
        id: 634235313234,
        product: 'Toshiba',
        img: 'https://f00.esfr.pl/foto/7/95790782721/c3871c3b7a637a001f1f34c2f7446790/toshiba-laptop-db-sat-pro-i5-16gb-256ssd-w10p,95790782721_8.jpg',
        customer: 'Rafał Kuchta',
        date: '1 May',
        amount: 2421,
        method: 'Cash on Delivery',
        status: 'Pending',
    }
];

export const List = () => {
    return (
        <TableContainer component={Paper} className='table'>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='tableCell'>List przewozowy</TableCell>
                        <TableCell className='tableCell'>Produkt</TableCell>
                        <TableCell className='tableCell'>Klient</TableCell>
                        <TableCell className='tableCell'>Data</TableCell>
                        <TableCell className='tableCell'>Ilość</TableCell>
                        <TableCell className='tableCell'>Metoda płatności</TableCell>
                        <TableCell className='tableCell'>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.id}
                        >
                            <TableCell className='tableCell'>{row.id}</TableCell>
                            <TableCell className='tableCell'>
                                <div className="cellWrapper">
                                    <img src={row.img} alt="img" className='image'/>
                                    {row.product}
                                </div>
                            </TableCell>
                            <TableCell className='tableCell'>{row.customer}</TableCell>
                            <TableCell className='tableCell'>{row.date}</TableCell>
                            <TableCell className='tableCell'>{row.amount}</TableCell>
                            <TableCell className='tableCell'>{row.method}</TableCell>
                            <TableCell className='tableCell'>
                                <span className={`status ${row.status}`}>{row.status}</span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
