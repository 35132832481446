export const userInputs = [
    {
        id: 1,
        label: 'Login',
        type: 'text',
        placeholder: 'john_doe',
    },
    {
        id: 2,
        label: 'Imię',
        type: 'text',
        placeholder: 'Johm',
    },
    {
        id: 3,
        label: 'Nazwisko',
        type: 'text',
        placeholder: 'Doe',
    },
    {
        id: 4,
        label: 'Email',
        type: 'email',
        placeholder: 'john@doe.pl',
    },
    {
        id: 5,
        label: 'Telefon',
        type: 'phone',
        placeholder: '+48 123 123 123',
    },
    {
        id: 6,
        label: 'Hasło',
        type: 'password',
    },
    {
        id: 7,
        label: 'Powtórz hasło',
        type: 'password',
    },
    {
        id: 8,
        label: 'Adres',
        type: 'text',
        placeholder: 'Adres',
    },
    {
        id: 9,
        label: 'Kraj',
        type: 'text',
        placeholder: 'Polska',
    }
];

export const productInputs = [
    {
        id: 1,
        label: 'Tytuł',
        type: 'text',
        placeholder: 'Apple Mackbook Pro',
    },
    {
        id: 2,
        label: 'Opis',
        type: 'text',
        placeholder: 'Opis',
    },
    {
        id: 3,
        label: 'Kategoria',
        type: 'text',
        placeholder: 'Komputery',
    },
    {
        id: 4,
        label: 'Cena',
        type: 'text',
        placeholder: '100',
    },
    {
        id: 5,
        label: 'Stock',
        type: 'text',
        placeholder: 'in stock',
    }
]