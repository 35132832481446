export const userColumns = [
    {field: 'id', headerName: 'ID', width: 70},
    {
        field: 'user',
        headerName: 'User',
        width: 230,
        renderCell: (params) => (
            <div className='cellWithImg'>
                <img className='cellImg' src={params.row.img} alt="avatar"/>
                {params.row.username}
            </div>
        )
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 230,
    },
    {
        field: 'age',
        headerName: 'Age',
        width: 100,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 160,
        renderCell: (params) => (
            <div className={`cellWithStatus ${params.row.status}`}>
                {params.row.status}
            </div>
        )
    }
]

export const userRows = [
    {
        id: 1,
        username: 'Rafał',
        img: 'https://media.istockphoto.com/id/1200677760/pl/zdj%C4%99cie/portret-przystojnego-u%C5%9Bmiechni%C4%99tego-m%C5%82odego-m%C4%99%C5%BCczyzny-ze-skrzy%C5%BCowanymi-ramionami.jpg?s=612x612&w=is&k=20&c=SMwF92lSVCI81n_t63uH4wGaGkT3ou530i_wZ3s09Gs=',
        status: 'active',
        email: 'r.kuchta@gba-polska.pl',
        age: 39,
    },
    {
        id: 2,
        username: 'Ela',
        img: 'https://images.pexels.com/photos/2531356/pexels-photo-2531356.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'passive',
        email: 't.ela@gba-polska.pl',
        age: 40,
    },
    {
        id: 3,
        username: 'Ewa',
        img: 'https://images.pexels.com/photos/4045599/pexels-photo-4045599.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'pending',
        email: 'm.ewa@gba-polska.pl',
        age: 35,
    },
    {
        id: 4,
        username: 'Krzysztof',
        img: 'https://images.pexels.com/photos/3619947/pexels-photo-3619947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'active',
        email: 'r.krzysztof@gba-polska.pl',
        age: 32,
    },
    {
        id: 5,
        username: 'Rafał',
        img: 'https://media.istockphoto.com/id/1200677760/pl/zdj%C4%99cie/portret-przystojnego-u%C5%9Bmiechni%C4%99tego-m%C5%82odego-m%C4%99%C5%BCczyzny-ze-skrzy%C5%BCowanymi-ramionami.jpg?s=612x612&w=is&k=20&c=SMwF92lSVCI81n_t63uH4wGaGkT3ou530i_wZ3s09Gs=',
        status: 'active',
        email: 'r.kuchta@gba-polska.pl',
        age: 39,
    },
    {
        id: 6,
        username: 'Ela',
        img: 'https://images.pexels.com/photos/2531356/pexels-photo-2531356.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'passive',
        email: 't.ela@gba-polska.pl',
        age: 40,
    },
    {
        id: 7,
        username: 'Ewa',
        img: 'https://images.pexels.com/photos/4045599/pexels-photo-4045599.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'pending',
        email: 'm.ewa@gba-polska.pl',
        age: 35,
    },
    {
        id: 8,
        username: 'Krzysztof',
        img: 'https://images.pexels.com/photos/3619947/pexels-photo-3619947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'active',
        email: 'r.krzysztof@gba-polska.pl',
        age: 32,
    },
    {
        id: 9,
        username: 'Rafał',
        img: 'https://media.istockphoto.com/id/1200677760/pl/zdj%C4%99cie/portret-przystojnego-u%C5%9Bmiechni%C4%99tego-m%C5%82odego-m%C4%99%C5%BCczyzny-ze-skrzy%C5%BCowanymi-ramionami.jpg?s=612x612&w=is&k=20&c=SMwF92lSVCI81n_t63uH4wGaGkT3ou530i_wZ3s09Gs=',
        status: 'active',
        email: 'r.kuchta@gba-polska.pl',
        age: 39,
    },
    {
        id: 10,
        username: 'Ela',
        img: 'https://images.pexels.com/photos/2531356/pexels-photo-2531356.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'passive',
        email: 't.ela@gba-polska.pl',
        age: 40,
    },
    {
        id: 11,
        username: 'Ewa',
        img: 'https://images.pexels.com/photos/4045599/pexels-photo-4045599.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'pending',
        email: 'm.ewa@gba-polska.pl',
        age: 35,
    },
    {
        id: 12,
        username: 'Krzysztof',
        img: 'https://images.pexels.com/photos/3619947/pexels-photo-3619947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        status: 'active',
        email: 'r.krzysztof@gba-polska.pl',
        age: 32,
    },

]