import React from 'react';
import './chart.scss';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'Styczeń',
        Total: 3200,
    },
    {
        name: 'Luty',
        Total: 2100,
    },
    {
        name: 'Marzec',
        Total: 900,
    },
    {
        name: 'Kwiecień',
        Total: 4000,
    },
    {
        name: 'Maj',
        Total: 2000,
    },
    {
        name: 'Czerwiec',
        Total: 6000,
    },

];

const Chart = ({aspect, title}) => {
    return (
        <div className='chart'>
            <div className="title">{title}</div>
            <ResponsiveContainer width="100%" aspect={aspect}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" className="chartGrid"/>
                    <XAxis dataKey="name" stroke="gray"/>
                    {/*<YAxis />*/}
                    <Tooltip />
                    <Legend />
                    {/*<Line type="monotone" dataKey="Total" stroke="#8884d8" activeDot={{ r: 8 }} />*/}
                    <Line type="monotone" dataKey="Total" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
};

export default Chart;