import React, {useState} from 'react';
import './new.scss';
import Navbar from "../../comonents/navbar/Navbar";
import Sidebar from "../../comonents/sidebar/Sidebar";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const New = ({inputs, title}) => {
    const [file, setFile] = useState('');
    console.log(file)

    return (
        <div className='new'>
            <Sidebar/>
            <div className="newContainer">
                <Navbar/>
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        <img src={
                            file
                                ? URL.createObjectURL(file)
                                : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
                        } alt="avatar"/>
                    </div>
                    <div className="right">
                        <form action="#">
                            <div className="formInput">
                                <label htmlFor='file'>
                                    Zdjęcie: <DriveFolderUploadIcon className='icon'/>
                                </label>
                                <input
                                    type="file"
                                    id='file'
                                    onChange={e => setFile(e.target.files[0])}
                                    style={{display: 'none'}}
                                />
                            </div>

                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label>{input.label}</label>
                                    <input
                                    type={input.type}
                                    placeholder={input.placeholder}
                                    />
                                </div>
                            ))}

                            <div className='btnSubmit'>
                                <button>Zapisz</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default New;